<template>
  <surround-box title="秸秆收纳总量">
    <div class="chart-box">
      <div ref="chart" class="chart"></div>
      <div class="unit">
        <!-- <div
          v-for="(item, i) in chooselist"
          :key="i"
          :class="item.isSelect ? 'btnactive' : 'btn'"
          @click="checkInfo(i)"
          style="cursor: pointer"
        >
          {{ item.text }}
        </div> -->
      </div>
    </div>
  </surround-box>
</template>
<script>
//秸秆收纳
import surroundBox from "@/components/mapComp/surroundBox2.vue";
import * as echarts from "echarts";

export default {
  components: {
    surroundBox,
  },
  props: {
    currentFarm: Object,
  },
  data() {
    return {
      data: [],
      unit: "",
      chooselist: [
        { text: "日", id: "1", isSelect: false, type: "day" },
        { text: "月", id: "2", isSelect: false, type: "month" },
        { text: "年", id: "3", isSelect: true, type: "year" },
      ],
      currIndexRecommend: Number,
      datas: [],
      type: "year",
    };
  },
  methods: {
    checkInfo(currIndexRecommend) {
      let id = this.chooselist[currIndexRecommend].id;
      let comboList = this.chooselist;
      for (let i = 0; i < comboList.length; i++) {
        comboList[i].isSelect = i == currIndexRecommend;
      }
      this.chooselist = comboList;
      this.type = this.chooselist[currIndexRecommend].type;
      this.getStaticss().then(() => {
        this.initChart();
      });
    },
    //   初始化
    initChart() {
      let chart = echarts.init(this.$refs.chart);
      let options = {
        grid: {
          containLabel: true,
          top: 32,
          left: 25,
          right: 32,
          bottom: 16,
        },
        xAxis: {
          data: this.datas.map((item) => item.time),
          axisLabel: {
            color: "#fff",
          },
          boundaryGap: false,
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: ["#fff"],
              type: "dashed",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ["#fff"],
              type: "dashed",
            },
          },
        },
        yAxis: {
          axisLabel: {
            color: "#fff",
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              type: "dashed",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ["#fff"],
              type: "dashed",
            },
          },
        },
        series: [
          {
            type: "line",
            data: this.datas.map((item) => item.val),
            lineStyle: {
              color: "#1DD8BE",
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(186, 255, 125, .8)",
                },
                {
                  offset: 0.8,
                  color: "rgba(29, 216, 190, 0)",
                },
              ]),
            },
          },
        ],
      };
      chart.setOption(options);
    },
    getStatics() {
      this.datas = [];
      return this.$post(this.$api.STATICS_MANAGE.GET2, {
        resourceTypeId: 136
      }).then((res) => {
        res.forEach((item) => {
          this.datas.push({
            time: item.dimensionName,
            val: item.content,
          });
        });
        this.unit = res[0].contentUnit;
      });
    },
    getStaticss() {
      this.datas = [];
      return this.$post(this.$api.STATICTICS_MAP.MUSCLE, {
        type: this.type,
      }).then((res) => {
        let timeLine = res.timeLine;
        for (let key in timeLine) {
          this.datas.push({
            time: key,
            val: timeLine[key],
          });
        }
      });
    },
  },
  mounted() {
    this.getStatics().then(() => {
      this.initChart();
    });
    // this.getStaticss().then(() => {
    //   this.initChart();
    // });
  },
};
</script>
<style lang='less' scoped>
.chart-box {
  width: 100%;
  height: 200px;
  position: relative;
  .chart {
    width: 100%;
    height: 200px;
  }
  .unit {
    position: absolute;
    width: 95%;
    left: 16px;
    top: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    .btn {
      width: 45px;
      height: 25px;
      border: 1px solid #343e4f;
      text-align: center;
      margin-right: 5px;
      justify-content: center;
      align-items: center;
      display: flex;
    }
    .btnactive {
      width: 45px;
      height: 25px;
      border: 1px solid #00fff7;
      text-align: center;
      margin-right: 5px;
      color: #00fff7;
      justify-content: center;
      align-items: center;
      display: flex;
    }
    .right-top {
      width: 8px;
      height: 8px;
      border-top: 2px solid #00fff7;
      border-right: 2px solid #00fff7;
      position: absolute;
      right: 0px;
      top: 0px;
    }
    .right-bottom {
      width: 8px;
      height: 8px;
      border-bottom: 2px solid #00fff7;
      border-right: 2px solid #00fff7;
      position: absolute;
      right: 0px;
      bottom: 0px;
    }
    .left-bottom {
      width: 16px;
      height: 16px;
      border-bottom: 2px solid #00fff7;
      border-left: 2px solid #00fff7;
      position: absolute;
      left: 0px;
      bottom: 0px;
    }
    .left-top {
      width: 8px;
      height: 8px;
      border-top: 2px solid #00fff7;
      border-right: 2px solid #00fff7;
      position: absolute;
      right: 0px;
      top: 0px;
    }
  }
}
</style>